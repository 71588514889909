import {useMutation, useQuery} from 'react-query';

import {useRecoilValue} from 'recoil';

import useUser from '@/hooks/useUser';
import {printCertificateService} from '@/services/certificateService';
import {
  getXProgressService,
  searchCourseDetailService,
} from '@/services/courseService';
import siteState from '@/stores/site';
import {CourseWithProgress} from '@/types/course.type';

export const useCourseDetail = (courseId: string) => {
  const {user} = useUser();

  const {data, isLoading, isFetching, refetch} = useQuery({
    queryKey: ['courseDetail', courseId, user?.id],
    queryFn: async ({queryKey}) => {
      const courseId = queryKey[1] as string;
      return await searchCourseDetailService(courseId, user?.id);
    },
  });

  return {data, isLoading: isLoading || isFetching, refetch};
};

export const useCourseGetCertificate = () => {
  const {mutateAsync, data, status, reset} = useMutation({
    mutationFn: async (courseId: string) => {
      return await printCertificateService(courseId);
    },
    onError: (error: any) => {
      throw error?.data?.error;
    },
  });

  return {mutateAsync, data: data?.data, status, reset};
};

export const useMyCourseDetail = (courseId: string) => {
  const {user} = useUser();
  const siteInfo = useRecoilValue(siteState.siteInfoState);

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['myCourseDetail', courseId, user?.id],
    queryFn: async ({queryKey}) => {
      const courseId = queryKey[1] as string;

      if (!siteInfo?.id) return;

      const myCourseResponse = await searchCourseDetailService(
        courseId,
        user?.id,
      );
      const progressResponse = await getXProgressService({
        siteId: siteInfo.id,
        xType: 'COURSE',
        courseId,
      });

      const newMyCourseContent: CourseWithProgress = {
        ...myCourseResponse,
        ...progressResponse[0],
      };
      return newMyCourseContent;
    },
  });

  return {data, isLoading, refetch};
};
