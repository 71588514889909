import {useNavigate} from 'react-router-dom';

import SampleCourseImage1 from '@/assets/img/course1.png';
import {NewPrimaryButton} from '@/components/common/NewButton';
import {CourseWithProgress} from '@/types/course.type';

export function CourseSimpleCard({
  courseItem,
}: {
  courseItem: CourseWithProgress;
}) {
  const navigate = useNavigate();
  const {progressRate = 0, attendRate = 0, homeworkRate = 0} = courseItem;
  const isCertificateIssuable =
    (progressRate >= 100 && attendRate >= 100 && homeworkRate >= 100) ||
    courseItem.completeDate;
  return (
    <div className="w-[340px] h-[405px] rounded-[10px] border border-[#e5e5e5]">
      <div className="w-[340px] h-[220px]">
        <img
          src={courseItem.thumbnailUrl || SampleCourseImage1}
          alt="교육과정 썸네일"
          className="w-full h-full object-cover rounded-t-[10px]"
        />
      </div>
      <div className="pt-[10px] pb-[25px] px-[20px]">
        <div className="h-[55px] mb-[4px]">
          <p className="text-secondary text-base font-bold leading-relaxed">
            {courseItem.name || '교육과정 이름'}
          </p>
        </div>
        <div className="flex flex-col mb-[12px] gap-1">
          <div className="flex gap-5">
            <span className="flex items-center text-[#737373] text-sm font-bold gap-[9px]">
              수강률
              <span className="text-[#666666] text-xs font-medium">
                {courseItem.progressRate}%
              </span>
            </span>
            <span className="flex items-center text-[#737373] text-sm font-bold gap-[9px]">
              출석률
              <span className="text-[#666666] text-xs font-medium">
                {courseItem.attendRate}%
              </span>
            </span>
          </div>
          <div className="flex gap-5">
            <span className="flex items-center text-[#737373] text-sm font-bold gap-[9px]">
              과제 수행률
              <span className="text-[#666666] text-xs font-medium">
                {courseItem.homeworkRate}%
              </span>
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <NewPrimaryButton
            button={isCertificateIssuable ? 'success' : 'disabled'}
            text={isCertificateIssuable ? '수료증 발급가능' : '미수료'}
            buttonStyle={{
              width: '120px',
              height: '34px',
              border: '2px',
              fontSize: '14px',
            }}
          />
          {courseItem.endsAt && new Date(courseItem.endsAt) < new Date() ? (
            <NewPrimaryButton
              button="disabled"
              text="과정종료"
              buttonStyle={{
                width: '78px',
                height: '34px',
                fontSize: '14px',
              }}
            />
          ) : (
            <NewPrimaryButton
              button="primary"
              text="수강하기"
              buttonStyle={{
                width: '78px',
                height: '34px',
                fontSize: '14px',
              }}
              onClick={() => {
                navigate(`/course/${courseItem.id}`);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
