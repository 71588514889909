import {useNavigate} from 'react-router-dom';

import {ReactComponent as ArrowRightThinIcon} from '@/assets/newImg/arrow-right-thin-s.svg';
import {ReactComponent as NonCoursesIcon} from '@/assets/newImg/main-non-course.svg';
import {NewLayout, Skeleton} from '@/components/common';
import {useMyCourse} from '@/hooks/useMyCourse';
import useUser from '@/hooks/useUser';
import {CourseSimpleCard} from '@/pages/new-user/components';
import {CourseWithProgress} from '@/types/course.type';
import {encodeHyphenPhoneNumber} from '@/utils/format';

function UserInfoWrapper({label, value}: {label: string; value: string}) {
  return (
    <div className="flex items-center gap-2.5">
      <p className="text-primary text-xl font-bold">{label}</p>
      <p className="text-[#404040] text-[25px] font-medium">{value}</p>
    </div>
  );
}

export function MyPage() {
  const navigate = useNavigate();
  const {user} = useUser();
  const {myCourseList, isLoading} = useMyCourse();

  return (
    <NewLayout bgWhite>
      <div className="flex flex-col pt-[50px] pb-[100px] gap-[23px]">
        <div className="flex flex-col desktop:flex-row items-start desktop:items-center justify-between px-[50px] py-[40px] bg-white border-gray-200 rounded-[30px] course-shadow">
          {user?.id ? (
            <>
              <div className="whitespace-nowrap desktop:mb-0 mb-6">
                <p className="text-[#666666] text-lg font-medium mb-6">
                  내 정보
                </p>
                <div className="flex flex-col desktop:flex-row desktop:gap-10 gap-3">
                  <UserInfoWrapper label="이름" value={user.name} />
                  <UserInfoWrapper label="이메일" value={user.email} />
                  <UserInfoWrapper
                    label="전화번호"
                    value={encodeHyphenPhoneNumber(user.phone)}
                  />
                </div>
              </div>
              <div className="flex items-center self-center gap-[25px] whitespace-nowrap">
                <div
                  className="w-28 h-[49px] px-[19px] py-3.5 bg-primary rounded-[30px] justify-center items-center gap-2.5 inline-flex cursor-pointer"
                  onClick={() => {
                    navigate('/user/update');
                  }}>
                  <p className="text-center text-white text-xl font-bold">
                    정보수정
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate('/user/withdraw');
                  }}>
                  <p className="text-[#808080] text-xl font-medium leading-[21px]">
                    회원탈퇴
                  </p>
                </div>
              </div>
            </>
          ) : (
            <Skeleton /> // TODO: 내 정보 스켈레톤 필요
          )}
        </div>
        <div className="px-[50px] py-[40px] flex flex-col bg-white border-gray-200 rounded-[30px] course-shadow">
          <div className="flex items-center justify-between text-lg font-medium mb-6">
            <p className="text-[#666666]">수강중인 교육과정</p>
            <div
              className="flex items-center gap-1.5 cursor-pointer"
              onClick={() => {
                navigate('/course');
              }}>
              <p className="text-primary">교육수강으로 이동</p>
              <ArrowRightThinIcon width={21} height={21} fill="#337ccf" />
            </div>
          </div>
          {isLoading ? (
            <Skeleton />
          ) : myCourseList.length > 0 ? (
            <div className="flex items-center gap-[30px]">
              {myCourseList.map(
                (courseItem: CourseWithProgress, index: number) => {
                  if (index > 2) return;
                  return (
                    <CourseSimpleCard
                      key={courseItem.id}
                      courseItem={courseItem}
                    />
                  );
                },
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-[22px]">
              <NonCoursesIcon width={194} height={154} />
              <p className="text-[#808080] text-xl font-medium">
                교육과정이 없습니다.
              </p>
            </div>
          )}
        </div>
      </div>
    </NewLayout>
  );
}
