import {AxiosResponse} from 'axios';

import {Api} from '@/App';

import {BaseResponse} from '@/types/common.type';

interface CertificateResponse {
  certificateUrl: string;
}

export function reqCertificate(
  courseId: string,
): Promise<AxiosResponse<BaseResponse<CertificateResponse>>> {
  return Api.get(`/courses/${courseId}/certificate`);
}
