import 'moment/locale/ko';
import moment, {locale} from 'moment';

locale('ko');

export function getToday() {
  return moment().format('YYYY-MM-DDTHH:mm');
}

export function genPerDate(date: string) {
  return moment(date).format('YY.MM.DD(ddd)');
}

export function genDurationDate(start: string, end: string): string {
  const startDate = moment(start).format('YY.MM.DD(ddd)');
  const endDate = moment(end).format('YY.MM.DD(ddd)');

  return `${startDate} ~ ${endDate}`;
}

export function replaceDateFormat(
  datetime: string,
  format: string,
  originFormat?: string,
) {
  if (originFormat) {
    return moment(datetime, originFormat).format(format);
  }

  return moment(datetime).format(format);
}

export function genTableIndex(
  page: number,
  size: number,
  index: number,
): number {
  return (page - 1) * size + index + 1;
}

export function genTableReverseIndex(
  page: number,
  size: number,
  index: number,
  total: number,
): number {
  return total - (page - 1) * size - index;
}

export function encodeHyphenPhoneNumber(phoneNumber: string) {
  return phoneNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
}

export function decodeHyphenPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/-/g, '');
}
